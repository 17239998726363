import styled, { FlattenSimpleInterpolation } from 'styled-components';

const Root = styled.div<{ styles?: FlattenSimpleInterpolation }>`
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;

    ${(props) => props.styles};

    &:after,
    &:before {
        top: 0;
        content: '';
        display: block;
        height: 100%;
        width: 3px;
        position: absolute;
        background-color: #000;
        border-radius: 10px;
    }

    &:before {
        left: 45%;
        transform: rotate(45deg);
    }

    &:after {
        left: 45%;
        transform: rotate(-45deg);
    }
`;

export const CloseIconSC = {
    Root,
};
