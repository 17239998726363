import { api } from 'api';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, FormElement, InputError } from 'shared/components/ui';
import { NotificationContext } from 'shared/providers';
import { EmailContactRequestValues } from 'shared/types';

import { HeroEmailFormSC } from './HeroForm.styles';

export const HeroEmailForm: React.FC = () => {
    const { t } = useTranslation();
    const { showNotification } = useContext(NotificationContext);

    // const handleFormSubmit = useCallback(() => {
    //     let link = `${process.env.REACT_APP_DASHBOARD_URL}/sign-up`;

    //     if (userEmail) link += `?email=${userEmail}`;

    //     window.open(link, '_blank');
    // }, [userEmail]);

    const handleFormSubmit = async (
        data: any,
        { resetForm }: { resetForm: () => void }
    ) => {
        const values: EmailContactRequestValues = {
            email: data.email,
            message: 'I want to test ecatch.',
        };

        const { status } = await api.subscription.subscribe(values);
        resetForm();
        if (status === 200) {
            showNotification('success', t('lp_form_hero_success'));
        }
    };

    const EmailInputValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('lp_hero_input_email_not_valid'))
            .required(t('lp_hero_input_email_empty')),
    });

    return (
        <HeroEmailFormSC.Root>
            <Formik
                initialValues={{ email: '' }}
                onSubmit={handleFormSubmit}
                validationSchema={EmailInputValidationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    resetForm,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormElement.Input
                            placeholder={t('lp_hero_input_email')}
                            styles={HeroEmailFormSC.InputCSS}
                            type="email"
                            name="email"
                            value={values.email}
                            id="email-contact-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <HeroEmailFormSC.ButtonWrapper>
                            <Button.Primary htmlTypes="submit">
                                {t('lp_hero_submit_email')}
                            </Button.Primary>
                        </HeroEmailFormSC.ButtonWrapper>
                        {errors.email && touched.email && (
                            <InputError>{errors.email}</InputError>
                        )}
                    </Form>
                )}
            </Formik>
            {/* <FormElement.Input
                placeholder={t('lp_hero_input_email')}
                styles={HeroEmailFormSC.InputCSS}
                type="email"
                id="email-contact-input"
                value={userEmail}
                onChange={handleEmailChange}
            />
            <HeroEmailFormSC.ButtonWrapper>
                <Button.Primary onClick={handleFormSubmit} htmlTypes="button">
                    {t('lp_hero_submit_email')}
                </Button.Primary>
            </HeroEmailFormSC.ButtonWrapper> */}
        </HeroEmailFormSC.Root>
    );
};
