import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Color } from 'shared/config';

const Root = styled.div<{
    isOpen: boolean;
    styles?: FlattenSimpleInterpolation;
}>`
    background-color: ${Color.lightish_blue};
    font-family: familyGTWalsheimPro, sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #fff;
    @extend %shared-hover-transition;
    ${(props) =>
        props.isOpen &&
        `
    opacity: 1;
    visibility: visible;
  `};
    ${(props) => props.styles};
`;

const Icon = styled.div`
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -7px;
    cursor: pointer;
`;

const CloseIcon = css`
    width: 16px;
    height: 16px;
    &:after,
    &:before {
        background-color: #fff !important;
    }
`;

export const NotificationSC = {
    Root,
    Icon,
    CloseIcon,
};
